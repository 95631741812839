
// auth
export const POST_LOGIN = "/auth/login"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"

export const GET_DASHBOARD_DATA = "/dashboard/dashboard"

//user
export const POST_CHANGE_PASSWORD = "/dashboard/change-password"

//setting
export const GET_ALL_SETTING = "/setting/get-all-setting"
export const POST_UPDATE_SETTING = "/setting/update-setting"

//agent
export const POST_AGENT_LIST = process.env.REACT_APP_API_URL+"agent/get-all-agent"
export const DELETE_AGENT = "/agent/delete"
export const POST_UPDATE_AGENT_STATUS = "/agent/update-status"

//seller
export const POST_SELLER_LIST = process.env.REACT_APP_API_URL+"seller/get-all-seller"
export const DELETE_SELLER = "/seller/delete"
export const POST_UPDATE_SELLER_STATUS = "/seller/update-status"

// buyer
export const POST_BUYER_LIST = process.env.REACT_APP_API_URL+"buyer/get-all-buyer"
export const POST_BUYER_DETAIL = "/buyer/detail"
export const DELETE_BUYER = "/buyer/delete"
export const POST_UPDATE_BUYER_STATUS = "/buyer/update-status"

//payment
export const GET_PAYMENT = process.env.REACT_APP_API_URL+"payment/get-all-payment"
export const GET_PAYMENT_DETAIL = "/payment/get-payment-detail"

// offer
export const GET_ALL_OFFERS = process.env.REACT_APP_API_URL+"offers/get-all-offers"

//properties
export const GET_PROPERTIES_LIST = process.env.REACT_APP_API_URL+"property/get-all-properties"
export const DELETE_PROPERTI = "/property/delete"
export const GET_PROPERTI_DETAIL = "/property/detail"
export const GET_PROPERTI_INFO = "/property/get-property-detail"
export const GET_PROPERTI_FORM_OPTION = "/property/get-property-form-option"
export const POST_ADD_EDIT_PROPERTY = "/property/add-edit-property"
export const DELETE_PROPERTY_IMAGE = "/property/delete-property-image"
export const GET_OFFER_PROCESS = "/property/get-offer-process"





//admin
export const POST_ADMIN_USER_LIST = process.env.REACT_APP_API_URL+"admin/get-all-user"
export const DELETE_ADMIN_USER = "/admin/delete"
export const POST_UPDATE_ADMIN_USER_STATUS = "/admin/update-status"
export const POST_ADD_EDIT_ADMIN_USER = "/admin/add-edit"
export const POST_GET_ADMIN_USER_DETAIL = "/admin/detail"
export const GET_ADMIN_FORM_OPTION = "/admin/get-form-option"

//user role
export const POST_ROLE_LIST = process.env.REACT_APP_API_URL+"user-roles/get-all-user-role"
export const DELETE_ROLE = "/user-roles/delete"
export const POST_UPDATE_ROLE_STATUS = "/user-roles/update-status"
export const POST_ADD_EDIT_ROLE = "/user-roles/add-edit"
export const POST_GET_ROLE_DETAIL = "/user-roles/detail"



