import React, { useState, useEffect } from "react";
import { useHistory, withRouter, Link } from "react-router-dom"
import { post } from '../../helpers/api_helper';
import { GET_PROPERTI_DETAIL } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { amountFormat, uct_local } from '../../helpers/Helper';
import { toast } from 'react-toastify';

import Loader from './../../component/Loader';
import transport_img from './../../assets/img/transport.png';
import avatar_img from './../../assets/img/avatar.png';
import image_coming_soon from './../../assets/img/image_coming_soon.png';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <svg onClick={onClick} style={{ "position": "absolute", "top": "45%", "right": "8px", 'width': '20px' }} xmlns="http://www.w3.org/2000/svg" width="28.164" height="24.086" viewBox="0 0 28.164 24.086">
            <g id="Group_24" data-name="Group 24" transform="translate(-571.539 -2113.957)">
                <path id="Path_1" data-name="Path 1" d="M0,0,9.922,9.87" transform="translate(587.711 2135.922) rotate(-90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3" />
                <path id="Path_2" data-name="Path 2" d="M0,9.87,9.922,0" transform="translate(587.711 2126) rotate(-90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3" />
                <path id="Path_3" data-name="Path 3" d="M0,22.841V0" transform="translate(573.039 2126) rotate(-90)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            </g>
        </svg>
    );
}
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <svg onClick={onClick} style={{ "position": "absolute", "top": "45%", "left": "8px", "fontSize": "25px", 'width': '20px', 'zIndex': '9' }} xmlns="http://www.w3.org/2000/svg" width="28.163" height="24.086" viewBox="0 0 28.163 24.086">
            <g id="Group_23" data-name="Group 23" transform="translate(599.703 2138.043) rotate(180)">
                <path id="Path_1" data-name="Path 1" d="M0,0,9.922,9.87" transform="translate(587.711 2135.922) rotate(-90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3" />
                <path id="Path_2" data-name="Path 2" d="M0,9.87,9.922,0" transform="translate(587.711 2126) rotate(-90)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3" />
                <path id="Path_3" data-name="Path 3" d="M0,22.841V0" transform="translate(573.039 2126) rotate(-90)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            </g>
        </svg>
    );
}


const settings_banne = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}


const PropertiDetaile = props => {
    let history = useHistory();

    const [PropertiDetail, SetPropertiDetail] = useState({});
    const [isLoader, setLoader] = useState(0);

    useEffect(() => {
        getPropertiDetail();
    }, [props])



    //UpdateStatus
    const getPropertiDetail = async () => {
        var res_data = await post(GET_PROPERTI_DETAIL, { propertyId: props.match.params.id });
        if (res_data.status) {
            SetPropertiDetail(res_data.detail);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
        setLoader(1);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tap Review | Property Detail</title>
            </Helmet>
            {!isLoader && <Loader />}

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <p className="m-0 mb-3"><Link to={`/properties`} className=""><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</Link></p>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Property Detail</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    {/* <Link to="users/add" className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="property_box">
                                        <div className='img_box'>
                                            <Slider {...settings_banne}>
                                                {PropertiDetail.property_image && Object.entries(PropertiDetail.property_image).map(([img_key, image]) => {
                                                    return (<img src={image.image} key={img_key} alt="Lights" className='w-100' />)
                                                })}
                                                {!PropertiDetail.property_image && <img src={image_coming_soon} alt="Lights" className='w-100' />}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <p className="mb-1"><b>{PropertiDetail.address}</b></p>
                                    <p className="mb-1">Property Type : <b>{PropertiDetail.propertyType}</b></p>
                                    <p className="mb-1">Bedrooms : <b>{PropertiDetail.bedrooms}</b></p>
                                    <p className="mb-1">Bathrooms : <b>{PropertiDetail.bathrooms}</b></p>
                                    <p className="mb-1">Price : <b>{PropertiDetail.minimumPrice > 0 && `${amountFormat(PropertiDetail.minimumPrice)} - `} {amountFormat(PropertiDetail.maximumPrice)}</b></p>
                                    <p className="mb-1">Status : <b>{PropertiDetail.propertyStatus}</b></p>
                                    <p className="mb-1">Added  on : <b>{uct_local(PropertiDetail.createdAt, 'DD/MM/YYYY')}</b></p>
                                </div>
                                <div className="col-sm-4">
                                    <h6 className="mb-1"><b>Agent Info</b></h6>
                                    <p className="mb-1">Name : <b>{PropertiDetail.agent_name}</b></p>
                                    <p className="mb-1">Email : <b>{PropertiDetail.agent_email}</b></p>
                                    <p className="mb-1">Phone No : <b>{PropertiDetail.agent_phone_no}</b></p>

                                    <h6 className="mb-1 mt-3"><b>Seller Info</b></h6>
                                    <p className="mb-1">Name : <b>{PropertiDetail.seller_name}</b></p>
                                    <p className="mb-1">Email : <b>{PropertiDetail.seller_email}</b></p>
                                    <p className="mb-1">Phone No : <b>{PropertiDetail.seller_phone_no}</b></p>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-sm-12">
                                    <h6 className="mb-1 mt-3"><b>Property Description</b></h6>
                                    <p style={{ whiteSpace: "pre-wrap" }}>{PropertiDetail.description}</p>
                                </div>
                                {/* <div className="col-sm-6">
                                    <h6 className="mb-1 mt-3"><b>Property Feature</b></h6>
                                    <p style={{ whiteSpace: "pre-wrap" }}>{PropertiDetail.feature}</p>
                                </div> */}
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-sm-6">
                                    {/* <h6 className="mb-1 mt-3"><b>Nearby Transport Lines</b></h6>
                                    <div className='row'>
                                        {(PropertiDetail.nearbyTransport && JSON.parse(PropertiDetail.nearbyTransport)) && Object.entries(JSON.parse(PropertiDetail.nearbyTransport)).map(([key, transport]) => (<>
                                            {transport.name && <div className='col-sm-6 mt-3'>
                                                <p style={{ whiteSpace: "pre-wrap" }}><img src={transport_img} width="40px" className='mr-2' />{transport.name}</p>
                                            </div>}
                                        </>))}
                                    </div> */}
                                </div>
                                <div className="col-sm-6">
                                    <iframe name="gMap" src={`https://maps.google.com/maps?q=${PropertiDetail.latitude},${PropertiDetail.longitude}&hl=es&z=14&output=embed`} width="100%" height="300" style={{ 'border': '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>

                            <hr />
                            <div className="row">
                                <div className="col-6">
                                    <h6 className="mb-1 mt-3 mb-3"><b>Property  offers</b></h6>
                                </div>
                                <div className="col-6 text-right pt-2">
                                    {PropertiDetail.propertyStatus == 'Sold out' && <Link to={`/view-process/${PropertiDetail.propertyId}`}>View process</Link>}
                                </div>
                                <div className="col-sm-12">

                                    {PropertiDetail.property_offer && Object.entries(PropertiDetail.property_offer).map(([key, value]) => {
                                        return (<>
                                            <div className='row seller-table-row'>
                                                <div className='col-sm-1 table-td'>
                                                    {!value.userImage && <img src={avatar_img} className="rounded-circle mr-3" width="45px" alt="user" />}
                                                    {value.userImage && <img src={value.userImage} className="rounded-circle mr-3" width="45px" alt="user" />}
                                                </div>
                                                <div className='col-sm-4 table-td align-self-center'>
                                                    <p className='mb-0'>{value.userName}</p>
                                                    <p className="mb-0">{value.email}</p>
                                                </div>
                                                <div className='col-sm-2 table-td align-self-center'>
                                                    <p className='mb-0'>{uct_local(value.createdAt, "DD/MM/YYYY HH:mm")}</p><small className='text-gray'>Offer Date</small>
                                                </div>
                                                <div className='col-sm-2 table-td align-self-center'>
                                                    {value.updatedAt && <>
                                                        <p className='mb-0'>{uct_local(value.updatedAt, "DD/MM/YYYY HH:mm")}</p>
                                                        <small className='text-gray'>Offer {value.status} Date</small>
                                                    </>}
                                                </div>
                                                <div className='col-sm-1 table-td align-self-center'>
                                                    <p className='mb-0'><b>£{value.amount}</b></p><small className='text-gray'>Offer</small>
                                                </div>
                                                <div className='col-sm-2 table-td align-self-center'>
                                                    {value.status == 'Pending' && <>
                                                        <button type='button' className='btn-sm c-bt-success-light ml-2 pl-3 pr-3 pt-1 pb-1 pointer border-0'>Pending</button>
                                                    </>}
                                                    {value.status == 'Accept' &&
                                                        <button type='button' className='btn-sm c-bt-success-light ml-2 pl-3 pr-3 pt-1 pb-1 pointer border-0'>
                                                            <i className="fa fa-check-circle text-success mr-2" aria-hidden="true"></i>
                                                            Accepted
                                                        </button>
                                                    }
                                                    {value.status == 'Decline' &&
                                                        <button type='button' className='btn-sm c-bt-danger-light ml-2 pl-3 pr-3 pt-1 pb-1 pointer border-0'>
                                                            <i className="fa fa-check-circle text-danger mr-2" aria-hidden="true"></i>
                                                            Decline
                                                        </button>
                                                    }
                                                    {value.status == 'Expired' &&
                                                        <button type='button' className='btn-sm c-bt-danger-light ml-2 pl-3 pr-3 pt-1 pb-1 pointer border-0'>
                                                            <i className="fa fa-check-circle text-danger mr-2" aria-hidden="true"></i>
                                                            Expired
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </>)
                                    })}
                                    {PropertiDetail.property_offer && PropertiDetail.property_offer.length <= 0 && <div> <h6 className='text-center mt-5 mb-5'>No offers to display</h6></div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(PropertiDetaile)
