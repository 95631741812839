import React, { useState, useEffect } from "react";
import { useHistory, withRouter, Link } from "react-router-dom"
import { post } from '../../helpers/api_helper';
import { GET_OFFER_PROCESS } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { uct_local } from '../../helpers/Helper';
import { toast } from 'react-toastify';

import Loader from '../../component/Loader';




const PropertiDetaile = props => {
    let history = useHistory();

    const [isLoader, setLoader] = useState(0);
    const [propertyProcess, setPropertyProcess] = useState(0);
    const [propertyInfo, setpropertyInfo] = useState({});
    const [faqsOpen, setFaqsOpen] = useState('0');
    const [Info, setInfo] = useState('');


    useEffect(() => {
        getofferProcessDetail();
    }, [props])

    //UpdateStatus
    const getofferProcessDetail = async () => {
        var res_data = await post(GET_OFFER_PROCESS, { propertyId: props.match.params.id });
        if (res_data.status) {
            setpropertyInfo(res_data.data.property_info);
            setPropertyProcess(res_data.data.category_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
        setLoader(1);
    }

    const faqOpn = (index) => {
        setFaqsOpen((faqsOpen == index) ? '' : index);
    };


    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>YouSold | View Process</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <p className="m-0 mb-3"><Link to={`/property-detail/${props.match.params.id}`} className=""><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</Link></p>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>View Process - {propertyInfo.address}</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    {/* <Link to="users/add" className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="row">
                                <div className='col-sm-12'>
                                    {Object.entries(propertyProcess).map(([key, value]) => {
                                        return (<>
                                            <div className={"c-accordion bg_white mb-3 " + (faqsOpen === key ? "open" : "")}>
                                                <div className='title pointer' onClick={faqOpn.bind(this, key)}>
                                                    <div className='row'>
                                                        <div className='col-sm-5'><p className='mb-0 align-self-center'>{value.name}</p></div>
                                                        <div className='col-sm-1 text-center'></div>
                                                        <div className='col-sm-1 text-center'></div>
                                                        <div className='col-sm-5 text-right'>
                                                            {value.totalTasksRemaining <= 0 && <button type='button' className='c-bt-success-light text-14 mr-3 border-0 pl-3 pr-3 pt-2 pb-2 pointer'>Completed</button>}
                                                            {value.totalTasksRemaining > 0 && <button type='button' className='c-bt-danger-light text-14 mr-3 border-0 pl-3 pr-3 pt-2 pb-2 pointer'>{value.totalTasksRemaining} Tasks remaining</button>}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.75" height="14.685" viewBox="0 0 12.75 14.685">
                                                                <g id="Group_19" data-name="Group 19" transform="translate(2127.414 -572.039) rotate(90)">
                                                                    <path id="Path_1" data-name="Path 1" d="M0,0,4.961,4.935" transform="translate(580.375 2126) rotate(-90)" fill="none" stroke="#0e1e37" stroke-linecap="round" stroke-width="2" />
                                                                    <path id="Path_2" data-name="Path 2" d="M0,4.935,4.961,0" transform="translate(580.375 2121.039) rotate(-90)" fill="none" stroke="#0e1e37" stroke-linecap="round" stroke-width="2" />
                                                                    <path id="Path_3" data-name="Path 3" d="M0,11.421V0" transform="translate(573.039 2121.039) rotate(-90)" fill="none" stroke="#0e1e37" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='body'>
                                                    {Object.entries(value.process).map(([processKey, processValue]) => {
                                                        return (<>
                                                            <div key={processKey} className='row sub-title ml-0 mr-0 pt-2 pb-2 mb-3'>
                                                                <div className='col-sm-12 col-md-12 col-lg-5 align-self-center'>
                                                                    <p className='mb-0 text-14'>{processValue.agentTitle}</p>
                                                                </div>
                                                                <div className="col-sm-12 col-md-12 col-lg-5">
                                                                    <div className="row">
                                                                        <div className='col-sm-4 pl-2 pr-2 text-center align-self-center'>
                                                                            {processValue.is_completed_buyer == 'Yes' && <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="20px" height="20px" ><g fill="#19d800" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ 'mix-blend-mode': 'normal' }}><g transform="scale(8.53333,8.53333)"><path d="M15,3c-6.627,0 -12,5.373 -12,12c0,6.627 5.373,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12zM21.707,12.707l-7.56,7.56c-0.188,0.188 -0.442,0.293 -0.707,0.293c-0.265,0 -0.52,-0.105 -0.707,-0.293l-3.453,-3.453c-0.391,-0.391 -0.391,-1.023 0,-1.414c0.391,-0.391 1.023,-0.391 1.414,0l2.746,2.746l6.853,-6.853c0.391,-0.391 1.023,-0.391 1.414,0c0.391,0.391 0.391,1.023 0,1.414z"></path></g></g></svg>}
                                                                            {processValue.is_completed_buyer == 'No' && <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0,0,256,256"><g fill="#0e1e37" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" ><g transform="scale(5.33333,5.33333)"><path d="M23.98242,3.97461c-11.02793,0 -20,8.97207 -20,20c0,11.02793 8.97207,20 20,20c11.02793,0 20,-8.97207 20,-20c0,-11.02793 -8.97207,-20 -20,-20zM20.95117,13.98047h5.03516c3.5,0 7,1.5 7,6.5c0,5 -3.5,6.5 -7,6.5h-4v7.57422c0,0.787 -0.63778,1.42578 -1.42578,1.42578h-0.06445c-0.401,0 -0.78536,-0.16036 -1.06836,-0.44336c-0.283,-0.283 -0.44141,-0.66541 -0.44141,-1.06641v-8.70508v-2.61914v-7.20117c0,-1.085 0.87984,-1.96484 1.96484,-1.96484zM21.98633,16.98047v6.16602v0.83398h4.03125c2.601,0 3.96875,-1.407 3.96875,-3.5c0,-2.157 -1.399,-3.5 -4,-3.5z"></path></g></g></svg>}
                                                                            {(processValue.is_completed_buyer == 'No' || processValue.is_completed_buyer == 'Yes') && <div> <small>Buyer </small> <br /> <small>{uct_local(processValue.buyerAccepDate, 'DD/MM/YYYY HH:mm')}</small></div>}
                                                                        </div>
                                                                        <div className='col-sm-4 pl-2 pr-2 text-center align-self-center'>
                                                                            {processValue.is_completed_seller == 'Yes' && <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="20px" height="20px" ><g fill="#19d800" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ 'mix-blend-mode': 'normal' }}><g transform="scale(8.53333,8.53333)"><path d="M15,3c-6.627,0 -12,5.373 -12,12c0,6.627 5.373,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12zM21.707,12.707l-7.56,7.56c-0.188,0.188 -0.442,0.293 -0.707,0.293c-0.265,0 -0.52,-0.105 -0.707,-0.293l-3.453,-3.453c-0.391,-0.391 -0.391,-1.023 0,-1.414c0.391,-0.391 1.023,-0.391 1.414,0l2.746,2.746l6.853,-6.853c0.391,-0.391 1.023,-0.391 1.414,0c0.391,0.391 0.391,1.023 0,1.414z"></path></g></g></svg>}
                                                                            {processValue.is_completed_seller == 'No' && <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0,0,256,256"><g fill="#0e1e37" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" ><g transform="scale(5.33333,5.33333)"><path d="M23.98242,3.97461c-11.02793,0 -20,8.97207 -20,20c0,11.02793 8.97207,20 20,20c11.02793,0 20,-8.97207 20,-20c0,-11.02793 -8.97207,-20 -20,-20zM20.95117,13.98047h5.03516c3.5,0 7,1.5 7,6.5c0,5 -3.5,6.5 -7,6.5h-4v7.57422c0,0.787 -0.63778,1.42578 -1.42578,1.42578h-0.06445c-0.401,0 -0.78536,-0.16036 -1.06836,-0.44336c-0.283,-0.283 -0.44141,-0.66541 -0.44141,-1.06641v-8.70508v-2.61914v-7.20117c0,-1.085 0.87984,-1.96484 1.96484,-1.96484zM21.98633,16.98047v6.16602v0.83398h4.03125c2.601,0 3.96875,-1.407 3.96875,-3.5c0,-2.157 -1.399,-3.5 -4,-3.5z"></path></g></g></svg>}
                                                                            {(processValue.is_completed_seller == 'No' || processValue.is_completed_seller == 'Yes') && <div><small>Seller</small> <br /> <small>{uct_local(processValue.sellerAccepDate, 'DD/MM/YYYY HH:mm')}</small></div>}
                                                                        </div>
                                                                        <div className='col-sm-4 pl-2 pr-2 text-center align-self-center'>
                                                                            {processValue.is_completed_agent == 'Yes' && <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="20px" height="20px" ><g fill="#19d800" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ 'mix-blend-mode': 'normal' }}><g transform="scale(8.53333,8.53333)"><path d="M15,3c-6.627,0 -12,5.373 -12,12c0,6.627 5.373,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12zM21.707,12.707l-7.56,7.56c-0.188,0.188 -0.442,0.293 -0.707,0.293c-0.265,0 -0.52,-0.105 -0.707,-0.293l-3.453,-3.453c-0.391,-0.391 -0.391,-1.023 0,-1.414c0.391,-0.391 1.023,-0.391 1.414,0l2.746,2.746l6.853,-6.853c0.391,-0.391 1.023,-0.391 1.414,0c0.391,0.391 0.391,1.023 0,1.414z"></path></g></g></svg>}
                                                                            {processValue.is_completed_agent == 'No' && <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0,0,256,256"><g fill="#0e1e37" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" ><g transform="scale(5.33333,5.33333)"><path d="M23.98242,3.97461c-11.02793,0 -20,8.97207 -20,20c0,11.02793 8.97207,20 20,20c11.02793,0 20,-8.97207 20,-20c0,-11.02793 -8.97207,-20 -20,-20zM20.95117,13.98047h5.03516c3.5,0 7,1.5 7,6.5c0,5 -3.5,6.5 -7,6.5h-4v7.57422c0,0.787 -0.63778,1.42578 -1.42578,1.42578h-0.06445c-0.401,0 -0.78536,-0.16036 -1.06836,-0.44336c-0.283,-0.283 -0.44141,-0.66541 -0.44141,-1.06641v-8.70508v-2.61914v-7.20117c0,-1.085 0.87984,-1.96484 1.96484,-1.96484zM21.98633,16.98047v6.16602v0.83398h4.03125c2.601,0 3.96875,-1.407 3.96875,-3.5c0,-2.157 -1.399,-3.5 -4,-3.5z"></path></g></g></svg>}
                                                                            {(processValue.is_completed_agent == 'No' || processValue.is_completed_agent == 'Yes') && <div><small>Agent</small> <br /> <small>{uct_local(processValue.agentAccepDate, 'DD/MM/YYYY HH:mm')}</small></div>}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-sm-13 col-md-12 col-lg-2 text-right align-self-center dropdown'>
                                                                    {(processValue.otherInformation == "Select solicitor" || processValue.otherInformation == "Select broker") && <>
                                                                        {processValue.selectOtherInformation && <>
                                                                            <span className='dropdown1'>
                                                                                <svg data-toggle="dropdown" className='mr-2' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 50 50">
                                                                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
                                                                                </svg>
                                                                                <div className='dropdown-menu c-submenu'>
                                                                                    <div className='menu-1 pl-2 pr-2'>
                                                                                        {JSON.parse(processValue.selectOtherInformation).companyName && <><small>{JSON.parse(processValue.selectOtherInformation).companyName}</small><br /></>}
                                                                                        <small>{JSON.parse(processValue.selectOtherInformation).name}</small><br />
                                                                                        <small>{JSON.parse(processValue.selectOtherInformation).email}</small><br />
                                                                                        <small>{JSON.parse(processValue.selectOtherInformation).phoneNumber}</small><br />
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        </>}
                                                                    </>}

                                                                    {processValue.info && <button type='button' onClick={() => { setInfo(processValue.info); }} data-toggle="modal" data-target="#ProgressInfoModal" className='c-bt-gray-light text-14  btn-sm mr-2 border-0 pl-3 pr-3 pt-2 pb-2 pointer mt-1 mb-1'>Info</button>}

                                                                </div>
                                                            </div>
                                                        </>)
                                                    })}
                                                </div>
                                            </div>
                                        </>)
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="ProgressInfoModal" tabIndex="-1" role="dialog" aria-labelledby="ProgressInfoModal" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered1" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="container">
                                <div className="row">
                                    <div className="col-10">
                                        <h5 className="modal-title mt-0" id="exampleModalLongTitle">Progress Info</h5>
                                    </div>
                                    <div className="col-2">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body bg_light_gray">
                            <div className='row mb-4'>
                                <div className='col-sm-12'>
                                    <p>{Info}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(PropertiDetaile)
