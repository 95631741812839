import React, { useState, useEffect, useRef } from "react";
import { useHistory, withRouter, Link } from "react-router-dom"
import { post, del } from '../../helpers/api_helper';
import { GET_PROPERTI_INFO, DELETE_PROPERTY_IMAGE, POST_ADD_EDIT_PROPERTY, GET_PROPERTI_FORM_OPTION } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import Autocomplete from "react-google-autocomplete";

import Loader from '../../component/Loader';

const PropertiDetaile = props => {
    let history = useHistory();

    const simpleValidatorStep = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({
        address: '',
        userId: '',
        latitude: '',
        longitude: '',
        addressType: '',
        bedrooms: '',
        bathrooms: '',
        description: '',
        feature: '',
        priceType: 'Fix',
        minimumPrice: '',
        maximumPrice: '',
        nearbyTransport: [{ "name": "" }]
    });
    const [isLoader, setLoader] = useState(0);
    const [, forceUpdate] = useState();
    const [agentList, setAgentList] = useState([]);
    const [sellerList, setSellerList] = useState([]);

    useEffect(() => {
        const start = async () => {
            if (props.match.params.id) {
                await getPropertiDetail();
            }
            await getForm_option();
            setLoader(1);
        }
        start();
    }, [props.match.params.id])

    //UpdateStatus
    const getForm_option = async () => {
        var res_data = await post(GET_PROPERTI_FORM_OPTION, {});
        if (res_data.status) {
            setAgentList(res_data.data.agent_list)
            setSellerList(res_data.data.seller_list)
        } else {
            toast.error(res_data.message);
            history.push("/");
        }

    }
    const getPropertiDetail = async () => {
        var res_data = await post(GET_PROPERTI_INFO, { propertyId: props.match.params.id });
        if (res_data.status) {
            setInputs(res_data.data.propert_info);
            console.log(res_data.data.propert_info.property_image);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }

    }


    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'property_image') {
            var property_image = (form_inputs.property_image) ? form_inputs.property_image : []
            for (var files of event.target.files) {

                var file_extension = files.name.split('.').pop();
                var file_size = files.size;
                var file_size = (file_size / 1024 / 1024).toFixed(1);

                var extension = ['jpg', 'jpeg', 'png'];
                if (!extension.includes(file_extension.toLowerCase())) {
                    toast.error('Allowed only file jpg , jpeg and png.');
                    event.target.value = null;
                    return false;
                }

                if (file_size > 2) {
                    toast.error('Max File size Allowed 2Mb.');
                    event.target.value = null;
                    return false;
                }

                var reader = new FileReader();
                reader.readAsDataURL(files);
                reader.onload = function (e) {
                    property_image.push({ id: 0, image: e.target.result, image_url: '' })
                    setInputs(inputs => ({ ...form_inputs, ['property_image']: property_image }));
                }

            }
        } else if (event.target.name === 'document') {
            var value = event.target.files[0];
            var file_extension = event.target.files[0].name.split('.').pop();
            var file_size = event.target.files[0].size;
            var file_size = (file_size / 1024 / 1024).toFixed(1);

            var extension = ['pdf'];
            if (!extension.includes(file_extension.toLowerCase())) {
                toast.error('Allowed only file pdf.');
                event.target.value = null;
                return false;
            }

            if (file_size > 5) {
                toast.error('Max File size Allowed 1Mb.');
                event.target.value = null;
                return false;
            }

            var file = event.target.files[0];
            var reader = new FileReader();
            reader.onload = function () {
                setInputs(inputs => ({ ...form_inputs, [event.target.name]: reader.result }));
            }
            reader.readAsDataURL(file);
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    const deleteImage = async (index, type, id = 0) => {
        if (type == 'transport') {
            var nearbyTransport = form_inputs.nearbyTransport;
            nearbyTransport.splice(index, 1);
            setInputs(inputs => ({ ...form_inputs, ['nearbyTransport']: nearbyTransport }));
        } else {
            if (id > 0) {
                SetButtonDisabled(true);
                var res_data = await del(DELETE_PROPERTY_IMAGE + '?id=' + id);
                if (res_data.status) {
                    // toast(res_data.message);
                    var property_image = form_inputs.property_image;
                    property_image.splice(index, 1);
                    setInputs(inputs => ({ ...form_inputs, ['property_image']: property_image }));
                } else {
                    toast.error(res_data.message);
                }
                SetButtonDisabled(false);
            } else {
                var property_image = form_inputs.property_image;
                property_image.splice(index, 1);
                setInputs(inputs => ({ ...form_inputs, ['property_image']: property_image }));
            }
        }

    }

    //addRow
    const addRow = () => {
        var data = (form_inputs.nearbyTransport) ? form_inputs.nearbyTransport : [];
        data.push({ name: '' });
        setInputs(inputs => ({ ...form_inputs, ['nearbyTransport']: data }));
    }


    //handleInputChangeMultiple
    const handleInputChangeMultiple = (index, event) => {
        var name = event.target.name;
        form_inputs.nearbyTransport[index][name] = event.target.value;
        setInputs(inputs => ({ ...form_inputs, ['nearbyTransport']: form_inputs.nearbyTransport }));
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidatorStep.current.allValid()
        if (!formValid) {
            simpleValidatorStep.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_ADD_EDIT_PROPERTY, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                if (form_inputs.propertyId) {
                    history.push("/properties");
                } else {
                    history.push("/properties");
                }
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }


    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('index', index.toString());
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();
    };

    const handleDrop = (e, newIndex) => {
        const oldIndex = parseInt(e.dataTransfer.getData('index'));
        const imagesCopy = [...form_inputs.property_image];
        const [draggedImage] = imagesCopy.splice(oldIndex, 1);
        imagesCopy.splice(newIndex, 0, draggedImage);
        setInputs(inputs => ({ ...form_inputs, ['property_image']: imagesCopy }));
    };

    if (!isLoader) {
        return (<Loader></Loader>);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>YouSold | Property Detail</title>
            </Helmet>

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <p className="m-0 mb-3"><Link to={`/properties`} className=""><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</Link></p>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Property Detail</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    {/* <Link to="users/add" className="btn btn-sm btn-primary pt-1 pb-1 pl-3 pr-3 c-btn-primary text-white">+ Add</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div>

                            </div>
                            <form className="av-invalid" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className='form-group'>
                                            <label>select Agent</label>
                                            <select className='form-control' name="userId" value={form_inputs.userId} onChange={handleInputChange}>
                                                <option value="">select Agent</option>
                                                {agentList && Object.entries(agentList).map(([key, user]) => {
                                                    return (<option value={user.userId} key={key}>{user.userName} ({user.email})</option>)
                                                })}
                                            </select>
                                            {simpleValidatorStep.current.message('select agent', form_inputs.userId, 'required')}
                                        </div>
                                        <div className='form-group'>
                                            <label>select Seller</label>
                                            <select className='form-control' name="sellerId" value={form_inputs.sellerId} onChange={handleInputChange}>
                                                <option value="">select Seller</option>
                                                {sellerList && Object.entries(sellerList).map(([key, user]) => {
                                                    return (<option value={user.userId} key={key}>{user.userName} ({user.email})</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className='form-group'>
                                            <label>House Number</label>
                                            <input type='text' name="houseNumber" className="form-control" value={form_inputs.houseNumber} onChange={handleInputChange} placeholder='Enter your house number' />
                                        </div>
                                        <div className='form-group'>
                                            <label>Your current address</label>
                                            {/* <Autocomplete
                                                apiKey="AIzaSyDjxXES9JKoY8Rb3fQ48-JHCNZzQOzO_fU"
                                                style={{ width: "90%" }}
                                                onPlaceSelected={(place) => {
                                                    console.log(place);
                                                }}
                                                options={{
                                                    types: ["(regions)"],
                                                    componentRestrictions: { country: "ru" },
                                                }}
                                                defaultValue="Amsterdam"
                                            /> */}
                                            <input type='text' name="address" className="form-control" value={form_inputs.address} onChange={handleInputChange} placeholder='Enter your current address' />
                                            {simpleValidatorStep.current.message('address', form_inputs.address.trim(), 'required')}
                                        </div>
                                        <div className='form-group'>
                                            <label>Postcode</label>
                                            <input type='text' name="postcode" className="form-control" value={form_inputs.postcode} onChange={handleInputChange} placeholder='Enter your postcode' />
                                            {simpleValidatorStep.current.message('postcode', form_inputs.postcode, 'required')}
                                        </div>
                                        <div className='form-group'>
                                            <label>Type of Address</label>
                                            <select className='form-control' name="addressType" value={form_inputs.addressType} onChange={handleInputChange}>
                                                <option value="">Select Address Type</option>
                                                <option value="Home">Home</option>
                                                <option value="Work">Work</option>
                                            </select>
                                            {simpleValidatorStep.current.message('address type', form_inputs.addressType, 'required')}
                                        </div>
                                        <div className='form-group'>
                                            <label>Property Type</label>
                                            <select className='form-control' name="propertyType" value={form_inputs.propertyType} onChange={handleInputChange}>
                                                <option value="">Select Property Type</option>
                                                <option value="House">House</option>
                                                <option value="Flat">Flat</option>
                                                <option value="Bungalow">Bungalow</option>
                                                <option value="Chalet">Chalet</option>
                                                <option value="Commercial Premises">Commercial Premises</option>
                                                <option value="Park Home">Park Home</option>
                                            </select>
                                            {simpleValidatorStep.current.message('property type', form_inputs.propertyType, 'required')}
                                        </div>
                                        <div className='form-group'>
                                            <label>Bedrooms</label>
                                            <select className='form-control' name="bedrooms" value={form_inputs.bedrooms} onChange={handleInputChange}>
                                                <option value="">Select bedrooms</option>
                                                <option value="1">Bedrooms 1</option>
                                                <option value="2">Bedrooms 2</option>
                                                <option value="3">Bedrooms 3</option>
                                                <option value="4">Bedrooms 4</option>
                                                <option value="5">Bedrooms 5</option>
                                                <option value="6">Bedrooms 6</option>
                                            </select>
                                            {simpleValidatorStep.current.message('bedrooms', form_inputs.bedrooms, 'required')}
                                        </div>
                                        <div className='form-group'>
                                            <label>Bathrooms</label>
                                            <select className='form-control' name="bathrooms" value={form_inputs.bathrooms} onChange={handleInputChange}>
                                                <option value="">Select Bathrooms</option>
                                                <option value="1">Bathrooms 1</option>
                                                <option value="2">Bathrooms 2</option>
                                                <option value="3">Bathrooms 3</option>
                                                <option value="4">Bathrooms 4</option>
                                                <option value="5">Bathrooms 5</option>
                                                <option value="6">Bathrooms 6</option>
                                            </select>
                                            {simpleValidatorStep.current.message('bathrooms', form_inputs.bathrooms, 'required')}
                                        </div>
                                        <div className='form-group'>
                                            <label>Property Description</label>
                                            <textarea type='text' name="description" value={form_inputs.description} onChange={handleInputChange} rows={8} className="form-control" placeholder='Message' />
                                        </div>
                                        {/* <div className='form-group'>
                                            <label>Property Feature</label>
                                            <textarea type='text' name="feature" value={form_inputs.feature} onChange={handleInputChange} rows={8} className="form-control" placeholder='Message' />
                                        </div> */}

                                    </div>
                                    <div className="col-sm-6">
                                        {/* <div className='form-group'>
                                            <div>
                                                <label>Nearby Transport Lines</label>
                                                {form_inputs.nearbyTransport && Object.entries(form_inputs.nearbyTransport).map(([key, transport]) => (<>
                                                    <div className='input-remove-icon' key={key}>
                                                        <input type='text' name="name" onChange={handleInputChangeMultiple.bind(this, key)} value={transport.name} className="form-control mb-3" placeholder='Enter Transport Name' />
                                                        <span className='icon' onClick={deleteImage.bind(this, key, 'transport')}><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                                                    </div>
                                                </>))}
                                            </div>
                                            <div className="text-right">
                                                <span className='mt-3 pointer' onClick={addRow}>+add</span>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <label>Select Price Type</label>
                                                <select className='form-control' name="priceType" value={form_inputs.priceType} onChange={handleInputChange}>
                                                    <option value="">Select Price Type</option>
                                                    <option value="Fix">Fix</option>
                                                    <option value="Range">Range </option>
                                                </select>
                                                {simpleValidatorStep.current.message('price type', form_inputs.priceType, 'required')}
                                            </div>
                                            {form_inputs.priceType == 'Range' && <div className="col-sm-4">
                                                <div className='form-group'>
                                                    <label>Minimum Price</label>
                                                    <input type='text' name="minimumPrice" value={form_inputs.minimumPrice} onChange={handleInputChange} className="form-control" placeholder='Enter' />
                                                    {/* {simpleValidatorStep.current.message('price', form_inputs.minimumPrice, 'required|currency')} */}
                                                </div>
                                            </div>}
                                            <div className="col-sm-4">
                                                <div className='form-group'>
                                                    {form_inputs.priceType == 'Range' && <label>Maximum Price</label>}
                                                    {form_inputs.priceType != 'Range' && <label>Price</label>}
                                                    <input type='text' name="maximumPrice" value={form_inputs.maximumPrice} onChange={handleInputChange} className="form-control" placeholder='Enter' />
                                                    {simpleValidatorStep.current.message('price', form_inputs.maximumPrice, 'required|currency')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label>Document</label>
                                            <div className='row'>
                                                <div className='col-10'>
                                                    <input type='file' name="document" onChange={handleInputChange} className="form-control" placeholder='Enter' />
                                                </div>
                                                <div className='col-2 text-center'>
                                                    {form_inputs.documentUrl && <a href={form_inputs.documentUrl} target='_blank' className='btn btn-primary  btn-sm pl-2 pt-1 pr-2 pb-1'><i className="fa fa-eye" aria-hidden="true"></i></a>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label>Property Images</label>
                                            <div className='Image_man_box' style={{height:'700px'}}>
                                                {form_inputs.property_image && form_inputs.property_image.map((value, index) => {
                                                    return (<div className='img_box' key={index}
                                                        draggable
                                                        onDragStart={e => handleDragStart(e, index)}
                                                        onDragOver={e => handleDragOver(e, index)}
                                                        onDrop={e => handleDrop(e, index)}
                                                        style={{ cursor: 'move'}}
                                                    >
                                                        {value.id > 0 && <img src={value.image_url} alt='image' style={{ 'maxWidth': '100%' }} />}
                                                        {value.id <= 0 && <img src={value.image} alt='image' style={{ 'maxWidth': '100%' }} />}
                                                        <span onClick={deleteImage.bind(this, index, 'property_image', value.id)}><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                                                    </div>);
                                                })}
                                                <label htmlFor="property_image" className='img_box add'>
                                                    <div >
                                                        <input id='property_image' name='property_image' type="file" multiple onChange={handleInputChange} className='d-none' accept="image/png, image/jpg, image/jpeg" />
                                                        <h3 className='mb-2 text-center'>+</h3>
                                                        <p className='text-center mb-0 lh-normal'><small>Accepted formats </small></p>
                                                        <p className='text-center mb-0 '><small>(Jpg,Jpeg,Png)</small></p>
                                                        <p className='text-center mb-0 lh-normal'><small>Size 3200 X 1300</small></p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label>Select Property Status</label>
                                            <select className='form-control' name="propertyStatus" value={form_inputs.propertyStatus} onChange={handleInputChange}>
                                                <option value="">Select Property Status</option>
                                                <option value="Active">Live</option>
                                                <option value="Deactive">Deactive</option>
                                                <option value="Sold out">Sold out</option>
                                            </select>
                                            {simpleValidatorStep.current.message('property status', form_inputs.propertyStatus, 'required')}
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <hr />
                                        <div className="text-center mt-4">
                                            <button disabled={ButtonDisabled} type="submit" className='btn btn-primary c-btn-primary pt-1 pb-1 pl-3 pr-3'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Submit</button>
                                            <Link to="/properties" className='btn btn-danger c-btn-danger ml-3 pt-1 pb-1 pl-3 pr-3'>Cancel</Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(PropertiDetaile)
