import React from "react"
import { withRouter } from "react-router-dom"
import {removeUserSession} from '../../helpers/Helper';
import { useHistory } from 'react-router-dom';

const Logout = props => {
  let history = useHistory();
  removeUserSession();
  history.push("/login");
  return <></>
}

export default withRouter(Logout)
