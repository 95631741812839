import React, { useState, useEffect } from 'react';
import { useHistory, withRouter, Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import { GET_ALL_OFFERS } from '../../helpers/url_helper';
import { getToken, amountFormat, uct_local } from '../../helpers/Helper';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

import Loader from '../../component/Loader';

const Payment = props => {
    let history = useHistory();
    const [isLoader, setLoader] = useState(1);

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": GET_ALL_OFFERS,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [] }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "offerId" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <span className='pointer ' style={{'color':'#4c4cff'}} onClick={() => { history.push('/property-detail/' + data.propertyId); }}>{data.address}</span>
                            </>, td)
                        }
                    },
                    { "data": "userName" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <span className='pointer ' style={{'color':'#4c4cff'}} onClick={() => { history.push('/buyer/detaile/' + data.userId); }}>{data.email}</span>
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(amountFormat(data.totalAmount), td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.status === 'Pending' && <button className="border-0 badge badge-defalt pt-1 pb-1" >Pending</button>}
                                {data.status === 'Accept' && <button className="border-0 badge badge-success pt-1 pb-1"  >Accepted</button>}
                                {data.status === 'Decline' && <button className="border-0 badge badge-danger pt-1 pb-1"  >Decline</button>}
                                {data.status === 'Expired' && <button className="border-0 badge badge-danger pt-1 pb-1"  >Expired</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.createdAt)}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.updatedAt)}
                            </>, td)
                        }
                    },
                    // {
                    //     "data": null, 'createdCell': (td, data) => {
                    //         ReactDOM.render(<>
                    //             <button onClick={getPaymentDetail.bind(this, data.invoiceId)} className="btn btn-sm btn-info pt-0 pb-0 pl-1 pr-1  mr-2" data-toggle="modal" data-target="#exampleModal"  ><i className="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                    //         </>, td)
                    //     }
                    // },
                ]
            });
        });
    }

    return (
        <>
            {!isLoader && <Loader />}
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Offers</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 ">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Property</td>
                                            <td>Buyer Name</td>
                                            <td>Buyer Email</td>
                                            <td>Offer</td>
                                            <td>Status</td>
                                            <td>Offer Date</td>
                                            <td>Offer Accept Date</td>
                                            {/* <td>Action</td> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Payment);
