import React, { useState, useEffect } from "react";
import { useHistory, withRouter, Link } from "react-router-dom"
import { post } from '../../helpers/api_helper';
import { GET_PROPERTI_DETAIL, POST_BUYER_DETAIL } from '../../helpers/url_helper';
import { Helmet } from "react-helmet";
import { amountFormat, uct_local } from '../../helpers/Helper';
import { toast } from 'react-toastify';

import Loader from '../../component/Loader';
import avatar_img from './../../assets/img/avatar.png';



const PropertiDetaile = props => {
    let history = useHistory();

    const [UserDetail, SetUserDetail] = useState({});
    const [OfferList, SetOfferList] = useState([]);
    const [isLoader, setLoader] = useState(0);

    useEffect(() => {
        getPropertiDetail();
    }, [props])



    //UpdateStatus
    const getPropertiDetail = async () => {
        var res_data = await post(POST_BUYER_DETAIL, { userId: props.match.params.id });
        if (res_data.status) {
            SetUserDetail(res_data.data.user_info);
            SetOfferList(res_data.data.offer_list);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
        setLoader(1);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tap Review | Buyer Detail</title>
            </Helmet>
            {!isLoader && <Loader />}

            <div className='row mt-3'>
                <div className='col-sm-12'>
                    <p className="m-0 mb-3"><Link to={`/buyer`} className=""><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</Link></p>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Buyer Detail</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className="mb-3">User Info</h6>
                                    <p className="mb-1">User Name : <b>{UserDetail.userName}</b></p>
                                    <p className="mb-1">User Email : <b>{UserDetail.userEmail}</b></p>
                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3">Buyer Information</h6>
                                    <p className="mb-1">First Name : <b>{UserDetail.firstName}</b></p>
                                    <p className="mb-1">Last Name : <b>{UserDetail.lastName}</b></p>
                                    <p className="mb-1">Date of Birth : <b>{UserDetail.dateOfBirth}</b></p>
                                    <p className="mb-1">Gender : <b>{UserDetail.gender}</b></p>
                                    <p className="mb-1">Current Address : <b>{UserDetail.address}</b></p>
                                    <p className="mb-1">Email Address : <b>{UserDetail.email}</b></p>
                                    <p className="mb-1">Phone Number : <b>{UserDetail.phoneNumber}</b></p>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className="mb-3">Financial Information</h6>
                                    <p className="mb-2"><b>Buying position : </b><br/>{UserDetail.buyingPosition}</p>
                                    <p className="mb-2"><b>Financial position : </b><br/>{(UserDetail.financialPosition == 'Other') ? UserDetail.financialPositionText : UserDetail.financialPosition}</p>
                                    <p className="mb-2"><b>Agreement in principle : </b><br/>{(UserDetail.agreementInPrinciple == 'No') ? 'No' : <a href={UserDetail.agreement} className="link" target="_blank">View agreement</a>}</p>
                                    <p className="mb-2"><b>How much deposit do you intend on placing down for your mortgage : </b><br/>{UserDetail.depositMortgageType} {UserDetail.depositMortgage}</p>
                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3">Other Information</h6>
                                    <p className="mb-2"><b>Details of mortgage broker : </b><br/> {UserDetail.detailsOfMortgage}</p>
                                    <p className="mb-2"><b>Details of solicitor : </b><br/> {UserDetail.detailsOfSolicitor}</p>
                                    <p className="mb-2"><b>Reason for purchase : </b><br/> {UserDetail.reasonForPurchase}</p>
                                    <p className="mb-2"><b>Other information you wish to share with the estate agent/seller : </b> <br/>{UserDetail.otherInformation}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-6">
                                    <h6 className="mb-1 mt-3 mb-3"><b>Property  offers list</b></h6>
                                </div>
                                <div className="col-6 text-right pt-2">
                                </div>
                                <div className="col-sm-12">

                                    {OfferList && Object.entries(OfferList).map(([key, value]) => {
                                        return (<>
                                            <div key={key} className='row seller-table-row'>
                                                <div className='col-sm-1 table-td align-self-center'>
                                                    <p className="mb-0 text-center">{(parseInt(key) + 1)}</p>
                                                </div>
                                                <div className='col-sm-4 table-td align-self-center'>
                                                    <p className='mb-0'>{value.address}</p>
                                                </div>
                                                <div className='col-sm-2 table-td align-self-center'>
                                                    <p className='mb-0'>{uct_local(value.createdAt, "DD/MM/YYYY HH:mm")}</p><small className='text-gray'>Offer Date</small>
                                                </div>
                                                <div className='col-sm-2 table-td align-self-center'>
                                                    {value.updatedAt && <>
                                                        <p className='mb-0'>{uct_local(value.updatedAt, "DD/MM/YYYY HH:mm")}</p>
                                                        <small className='text-gray'>Offer {value.status} Date</small>
                                                    </>}
                                                </div>
                                                <div className='col-sm-1 table-td align-self-center'>
                                                    <p className='mb-0'><b>£{value.totalAmount}</b></p><small className='text-gray'>Offer</small>
                                                </div>
                                                <div className='col-sm-2 table-td align-self-center'>
                                                    {value.status == 'Pending' && <>
                                                        <button type='button' className='btn-sm c-bt-success-light ml-2 pl-3 pr-3 pt-1 pb-1 pointer border-0'>Pending</button>
                                                    </>}
                                                    {value.status == 'Accept' &&
                                                        <button type='button' className='btn-sm c-bt-success-light ml-2 pl-3 pr-3 pt-1 pb-1 pointer border-0'>
                                                            <i className="fa fa-check-circle text-success mr-2" aria-hidden="true"></i>
                                                            Accepted
                                                        </button>
                                                    }
                                                    {value.status == 'Decline' &&
                                                        <button type='button' className='btn-sm c-bt-danger-light ml-2 pl-3 pr-3 pt-1 pb-1 pointer border-0'>
                                                            <i className="fa fa-check-circle text-danger mr-2" aria-hidden="true"></i>
                                                            Decline
                                                        </button>
                                                    }
                                                    {value.status == 'Expired' &&
                                                        <button type='button' className='btn-sm c-bt-danger-light ml-2 pl-3 pr-3 pt-1 pb-1 pointer border-0'>
                                                            <i className="fa fa-check-circle text-danger mr-2" aria-hidden="true"></i>
                                                            Expired
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </>)
                                    })}
                                    {OfferList.length <= 0 && <div> <h6 className='text-center mt-5 mb-5'>No any offers</h6></div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default withRouter(PropertiDetaile)
