import React, { useState, useEffect } from 'react';
import { useHistory, withRouter, Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import { del } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { GET_PROPERTIES_LIST, DELETE_PROPERTI } from '../../helpers/url_helper';
import { getToken, amountFormat, uct_local } from '../../helpers/Helper';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

import Loader from '../../component/Loader';

const PropertiesList = props => {
    let history = useHistory();
    const [propertyId, SetPropertyId] = useState(0);
    const [isLoader, setLoader] = useState(1);

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": GET_PROPERTIES_LIST,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [6, 7] }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "propertyId" },
                    { "data": "userName" },
                    { "data": "email" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<><span style={{ 'whiteSpace': 'nowrap', 'display': 'inline-block', 'textOverflow': 'ellipsis', 'overflow': 'hidden', 'maxWidth': '350px' }}>{data.address}</span></>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <span style={{'whiteSpace': 'nowrap'}}>
                                    {data.minimumPrice > 0 && <> {amountFormat(data.minimumPrice)} - </>}
                                    {amountFormat(data.maximumPrice)}
                                </span>
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.createdAt, 'DD/MM/YYYY')}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.propertyStatus === 'Active' && <button className="border-0 badge badge-success pt-1 pb-1" >Active</button>}
                                {data.propertyStatus === 'Sold out' && <button className="border-0 badge badge-warning pt-1 pb-1" >Sold STC</button>}
                                {data.propertyStatus === 'Deactive' && <button className="border-0 badge badge-danger pt-1 pb-1"  >Deactive</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button className="btn btn-sm btn-info pt-0 pb-0 pl-1 pr-1  mr-2 mb-1" onClick={() => { history.push('/property-detail/' + data.propertyId); }} ><i className="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                <button className="btn btn-sm btn-primary pt-0 pb-0 pl-1 pr-1  mr-2 mb-1" onClick={() => { history.push('/property/edit/' + data.propertyId); }} ><i className="fa fa-edit" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                <button className="btn btn-sm btn-danger pt-0 pb-0 pl-1 pr-1  mr-2 mb-1" onClick={() => { SetPropertyId(data.propertyId); }} data-toggle="modal" data-target="#deleteModal" ><i className="fa fa-trash-o" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                                {data.propertyStatus === 'Sold out' && <button className="btn btn-sm btn-success pt-0 pb-0 pl-1 pr-1  mr-2 mb-1" onClick={() => { history.push('/view-process/' + data.propertyId); }} >P</button>}
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    // remove user role
    const remove_row = async () => {
        var res_data = await del(DELETE_PROPERTI + "?propertyId=" + propertyId);
        if (res_data.status) {
            toast(res_data.message);
            get_list();
        } else {
            toast.error(res_data.message);
        }
    }
    return (
        <>
            {!isLoader && <Loader />}
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Properties List</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                    <Link to="/property/add" className="btn btn-sm btn-primary c-btn-primary">+ Add Property</Link>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 ">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Agent Name</td>
                                            <td>Agent Email</td>
                                            <td>Address</td>
                                            <td>Price</td>
                                            <td>Added On</td>
                                            <td>Status</td>
                                            <td style={{'width' : '120px !important'}}>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Confirm Delete</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this record?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-sm btn-dnager c-btn-danger text-white" data-dismiss="modal">No</button>
                                <button onClick={remove_row.bind(this)} data-dismiss="modal" className="btn btn-sm btn-primary c-btn-primary">Yes</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default withRouter(PropertiesList);
