import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';
import { post } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { GET_PAYMENT, GET_PAYMENT_DETAIL } from '../../helpers/url_helper';
import { getToken, amountFormat, uct_local } from '../../helpers/Helper';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";

import Logo from './../../assets/img/black_logo.png';
import Loader from './../../component/Loader';

const Payment = props => {
    let history = useHistory();
    const [PaymentDetail, SetPaymentDetail] = useState({});
    const [isLoader, setLoader] = useState(1);

    useEffect(() => {
        get_list();
    }, [])

    // get list
    const get_list = () => {
        $(document).ready(function () {
            $('#dataTableExample').DataTable({
                destroy: true,
                "bProcessing": true,
                "bServerSide": true,
                'searching': true,
                'stateSave': true,
                "scrollX": true,
                "sServerMethod": "POST",
                "sAjaxSource": GET_PAYMENT,
                "order": [[0, 'desc']],
                columnDefs: [{ orderable: false, targets: [6,7] }],
                "fnServerData": function (sSource, aoData, fnCallback) {
                    $.ajax({
                        "dataType": 'json',
                        "type": "POST",
                        "url": sSource,
                        "data": aoData,
                        "success": fnCallback,
                        headers: { 'Authorization': getToken() },
                    });
                },
                "columns": [
                    { "data": "invoiceId" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {uct_local(data.createdAt)}
                            </>, td)
                        }
                    },
                    { "data": "name" },
                    { "data": "email" },
                    { "data": "phoneNumber" },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(amountFormat(data.totalAmount), td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                {data.paymentStatus === 'Pending' && <button className="border-0 badge badge-danger pt-1 pb-1" >Pending</button>}
                                {data.paymentStatus === 'Complete' && <button className="border-0 badge badge-success pt-1 pb-1"  >Complete</button>}
                            </>, td)
                        }
                    },
                    {
                        "data": null, 'createdCell': (td, data) => {
                            ReactDOM.render(<>
                                <button onClick={getPaymentDetail.bind(this, data.invoiceId)} className="btn btn-sm btn-info pt-0 pb-0 pl-1 pr-1  mr-2" data-toggle="modal" data-target="#exampleModal"  ><i className="fa fa-eye" style={{ 'fontSize': '12px' }} aria-hidden="true"></i></button>
                            </>, td)
                        }
                    },
                ]
            });
        });
    }

    const getPaymentDetail = async (invoiceId) => {
        setLoader(0);
        var res_data = await post(GET_PAYMENT_DETAIL, { invoiceId: invoiceId });
        if (res_data.status) {
            SetPaymentDetail(res_data.data);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
        setLoader(1);
    }
    return (
        <>
            {!isLoader && <Loader />}
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='card'>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className='mt-2 mb-0'>Payment History</h6>
                                </div>
                                <div className="col-sm-6 text-right">
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className="mt-2 ">
                                <table id="dataTableExample" className="table table-striped ">
                                    <thead>
                                        <tr>
                                            <td>Id</td>
                                            <td>Date</td>
                                            <td>Name</td>
                                            <td>Email</td>
                                            <td>Phone Number</td>
                                            <td>Amount</td>
                                            <td>Payment Status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Payment Detail</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='row mb-2'>
                                                <div className='col-12 text-center'>
                                                    <img src={Logo} width={'150px'} />
                                                </div>
                                            </div>
                                            <hr className="my-4" />

                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <h6 className="font-size-16 mb-3"><b>From :</b></h6>
                                                    <p className="font-size-15 mb-1"><b>YouSold</b></p>
                                                    <p className="mb-1">info@yousold.com</p>
                                                    <p className="mb-1">12345 67890</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h6 className="font-size-16 mb-3"><b>To:</b></h6>
                                                    <p className="font-size-15 mb-1"><b>{PaymentDetail.name}</b></p>
                                                    <p className="mb-1">{PaymentDetail.email}</p>
                                                    <p className="mb-1">{PaymentDetail.phoneNumber}</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <h6 className="font-size-16 mb-3"><b>Details:</b></h6>
                                                    <p className="font-size-15 mb-1">Invoice <b>#{PaymentDetail.invoiceId}</b></p>
                                                    <p className="mb-1">Date : {uct_local(PaymentDetail.createdAt)}</p>
                                                    <p className="mb-1">Payment Status :
                                                        {PaymentDetail.paymentStatus == 'Complete' && <span className="badge bg-success font-size-12 ms-2 ml-2">Paid</span>}
                                                        {PaymentDetail.paymentStatus == 'Pending' && <span className="badge bg-danger font-size-12 ms-2 ml-2">Pending</span>}
                                                    </p>
                                                    <p className="mb-1">Tx Id : {PaymentDetail.paymentTransactionId}</p>
                                                </div>
                                            </div>

                                            <hr className="my-4" />
                                            <div className="row">
                                                <div className='col-sm-12'>
                                                    <table className='table table-striped'>
                                                        <thead>
                                                            <tr>
                                                                <th width="50px" className='text-center'>#</th>
                                                                <th>Description</th>
                                                                <th className='text-right' width="100px">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td className='text-center'>1</td>
                                                                <td>{PaymentDetail.description}</td>
                                                                <td className='text-right'>{amountFormat(PaymentDetail.amount)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='col-8'></div>
                                                <div className='col-4'>
                                                    <table className='table '>
                                                        <tbody>
                                                            <tr>
                                                                <td className='text-right'>Subtotal</td>
                                                                <td className='text-right'>{amountFormat(PaymentDetail.amount)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-right'>VAT {PaymentDetail.vatPercentage}%</td>
                                                                <td className='text-right'>{amountFormat(PaymentDetail.vat)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-right'><b>Total</b></td>
                                                                <td className='text-right'><b>{amountFormat(PaymentDetail.totalAmount)}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(Payment);
