import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './layouts/App_route';
import UserRoute from './layouts/UserRoute';

import { BrowserRouter } from 'react-router-dom';

import Default from './layouts/Default';
import Full from './layouts/Full';

// pages
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login';
import Logout from "./pages/Auth/Logout";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ChangePassword from "./pages/Auth/ChangePassword";

// agent
import AgentList from "./pages/Agent/AgentList";

//seller
import SellerList from "./pages/Seller/SellerList";

//users
import BuyerList from "./pages/Buyer/BuyerList";
import BuyerDetaile from "./pages/Buyer/BuyerDetaile";

//payment
import PaymentList from "./pages/Payment/PaymentList";

//Offers
import OffersList from "./pages/Offers/OffersList";


//PropertiesList
import PropertiesList from "./pages/Properties/PropertiesList";
import PropertiDetaile from "./pages/Properties/PropertiDetaile";
import PropertyForm from "./pages/Properties/PropertyForm";
import PropertiViewProcess from "./pages/Properties/PropertiViewProcess";


//Admin
import AdminList from "./pages/Admin/AdminList";
import AdminForm from "./pages/Admin/AdminForm";


// setting
import Setting from "./pages/Setting/Setting";
import EmailSetting from "./pages/Setting/EmailSetting";
import ApiLogSetting from "./pages/Setting/ApiLogSetting";

//User Role
import UserRolesList from "./pages/UserRoles/UserRolesList";
import UserRolesForm from "./pages/UserRoles/UserRolesForm";

function App() {
    return (
        <>
        <BrowserRouter>
            <Switch>
                <AppRoute exact path="/login" component={Login} layout={Full} />
                <AppRoute exact path="/logout" component={Logout} layout={Full} />
                <AppRoute exact path="/forgot-password" component={ForgetPassword} layout={Full} />
                
                <UserRoute exact path="/change-password" component={ChangePassword} layout={Default} />

                <UserRoute exact path="/agent" component={AgentList} layout={Default} />
                <UserRoute exact path="/seller" component={SellerList} layout={Default} />
                <UserRoute exact path="/buyer" component={BuyerList} layout={Default} />
                <UserRoute exact path="/buyer/detaile/:id?" component={BuyerDetaile} layout={Default} />


                {/* Setting */}
                <UserRoute exact path="/setting" component={Setting} layout={Default} />
                <UserRoute exact path="/setting/email" component={EmailSetting} layout={Default} />
                <UserRoute exact path="/setting/api-log" component={ApiLogSetting} layout={Default} />

                {/* admin */}
                <UserRoute exact path="/admin" component={AdminList} layout={Default} />
                <UserRoute exact path="/admin/add" component={AdminForm} layout={Default} />
                <UserRoute exact path="/admin/edit/:id?" component={AdminForm} layout={Default} />
               
             
                {/* PaymentList */}
                <UserRoute exact path="/payment" component={PaymentList} layout={Default} />

                {/* OffersList */}
                <UserRoute exact path="/offers" component={OffersList} layout={Default} />

                {/* PropertiesList */}
                <UserRoute exact path="/properties" component={PropertiesList} layout={Default} />
                <UserRoute exact path="/property-detail/:id?" component={PropertiDetaile} layout={Default} />
                <UserRoute exact path="/property/add" component={PropertyForm} layout={Default} />
                <UserRoute exact path="/property/edit/:id?" component={PropertyForm} layout={Default} />
                <UserRoute exact path="/view-process/:id?" component={PropertiViewProcess} layout={Default} />
                
                {/* user role */}
                <UserRoute exact path="/user-role" component={UserRolesList} layout={Default} />
                <UserRoute exact path="/user-role/add" component={UserRolesForm} layout={Default} />
                <UserRoute exact path="/user-role/edit/:id?" component={UserRolesForm} layout={Default} />
                
                <UserRoute exact path="/" component={Dashboard} layout={Default} />
            </Switch>
        </BrowserRouter> 
    </>
  );
}

export default App;
